import { Image } from "../image";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./style.css";

// Import required modules
import { Navigation } from "swiper";

export const Gallery = (props) => {
    const handleClick = (event) => {
        console.log("Clicked");
        event.preventDefault();
    };

    return (
        <div id="portfolio" className="text-center">
            <div className="container">
                <div className="section-title">
                    <h2>Projetos</h2>
                    <p></p>
                </div>
                <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    spaceBetween={50}
                    slidesPerView={3}
                    breakpoints={{
                        300: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 1,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                    }}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    className="mySwiper"
                >
                    <div className="portfolio-items">
                        {props.data
                            ? props.data.map((d, i) => (
                                <div
                                    key={`${d.title}-${i}`}
                                    className="col-sm-6 col-md-4 col-lg-4"
                                >
                                    <SwiperSlide onClick={handleClick}>
                                        <Image
                                            title={d.title}
                                            largeImage={d.largeImage}
                                            smallImage={d.largeImage}
                                            alt={d.alt}
                                        ></Image>
                                    </SwiperSlide>
                                </div>
                            ))
                            : "Loading..."}
                    </div>
                </Swiper>
            </div>
        </div>
    );
};
